document.querySelector('#nav-logo').addEventListener('click', function (e) {
    e.preventDefault();
    fullpage_api.moveTo(1);
});
document.querySelector('.alchemy-logo').addEventListener('click', function (e) {
    e.preventDefault();
    fullpage_api.silentMoveTo(3);
});
document.querySelector('.remixtura-logo').addEventListener('click', function (e) {
    e.preventDefault();
    fullpage_api.silentMoveTo(4);
});
// document.querySelector('.ptt-logo').addEventListener('click', function (e) {
//     e.preventDefault();
//     fullpage_api.silentMoveTo(5);
// });
document.querySelector('.dogezer-logo').addEventListener('click', function (e) {
    e.preventDefault();
    fullpage_api.silentMoveTo(5);
});