import '../styles/main.scss';
import $ from 'jquery';
import jQuery from 'jquery';
window.$ = jQuery;
import './animations';
import './tawk';
import './slider';
import './fullpage';
import './navigation';
import './form-animation';
import './technologies-animation';
import './ajax_submit';
import './lang';
import "particles.js";

$(document).ready(function () {
    let bg = document.getElementsByClassName('slide-background')[0].style.cssText += 'background: -webkit-gradient(linear, left top, right bottom, from(#0C051B), to(#0C051B)) ;';
    $('.main-preloader').remove();
    setTimeout(function () {
        particlesJS('particles-js', {
        "particles": {
            "number": {
                "value": 150,
                "density": {
                    "enable": true,
                    "value_area": 1500
                }
            },
            "color": {
                "value": ["#F7C34C", "#F7C34C"]
            },
            "shape": {
                "type": "circle",

                "stroke": {
                    "width": 0,
                    "color": "#000000"
                },
                "polygon": {
                    "nb_sides": 1
                }
            },
            "size": {
                "value": 3.6,
                "random": true,
                "anim": {
                    "enable": true,
                    "speed": 1,
                    "size_min": 0.5,
                    "sync": true
                }
            },
            "line_linked": {
                "enable": true,
                "distance": 100,
                "color": "#F7C34C",
                "opacity": 0.2,
                "width": 1
            },
            "move": {
                "enable": true,
                "speed": 2,
                "direction": "left",
                "random": true,
                "straight": true,
                "out_mode": "out",
                "bounce": false,
                "attract": {
                    "enable": true,
                    "rotateX": 600,
                    "rotateY": 1200
                }
            }
        },
        "interactivity": {
            "events": {
                "onclick": {
                    "enable": false,
                },
                "onhover": {
                    "enable": false
                },
                "resize": true
            },

        },
        "retina_detect": true
    });
    }, 500);
    if (!window.location.hash) {
        window.location.hash = '#about-us';
    }
});





