let toggler = document.querySelector("#lang-toggle");
function langToggle() {
    const lang = window.navigator.language.split('-')[0];
    if (location.pathname === '/') {
        document.cookie = 'user_language=ru';
        location.pathname = '/ru/';
    } else {
        document.cookie = `user_language=${lang}`;
        location.pathname = `/${lang}/`;
    }
}
toggler.addEventListener("click", langToggle);