export let welcome_background_color = '-webkit-gradient(linear, left top, right bottom, from(#0C051B), to(#0C051B)) ';
export let solutions_background_color = '-webkit-gradient(linear, left top, right bottom, from(#071030), to(#0C1B50)) ';
export let alchemy_background_color = '-webkit-gradient(linear, left top, right bottom, from(#0B062C), to(#12586F)) ';
export let remixtura_background_color = '-webkit-gradient(linear, left top, right bottom, from(#141B4A), to(#311679)) ';
export let ptt_background_color = '-webkit-gradient(linear, left top, right bottom, from(#10214B), to( #602475)) ';
export let dogezer_background_color = '-webkit-gradient(linear, left top, right bottom, from(#25105D), to(#3D30AE)) ';
export let technologies_background_color = '-webkit-gradient(linear, left top, right bottom, from(#0C051B), to(#0C051B)) ';
export let team_background_color = '-webkit-gradient(linear, left top, right bottom, from( #071030), to(#0C1B50)) ';
export let footer_background_color = '-webkit-gradient(linear, left top, right bottom, from( #071030), to(#0C1B50)) ';
export let welcome_color = '#482929';
export let solutions_color = '#360033';
export let alchemy_color = '#1A1A7A';
export let remixtura_color = '#181D3C';
export let ptt_color = '#2A0E50';
export let dogezer_color = '#25105D';
export let technologies_color = '#482929';
export let team_color = '#4B104D';
export let footer_color = '#0B062C';






