import $ from 'jquery';
import {footer_background_color, team_background_color} from "./constants";

document.querySelector("#form-submit-btn").addEventListener("click", submit);

function submit(e) {
    let name = document.getElementById('input-name');
    let message = document.getElementById('input-message');
    let email = document.getElementById('input-email');
    let subject = document.getElementById('input-subject');
    const formTL = gsap.timeline({
        defaults: {duration: 2, ease: "power2.easeInOut"}
    });
    if (name.checkValidity() && message.checkValidity() && email.checkValidity() && subject.checkValidity()) {

        formTL.to('#form-submit-btn', 0, {pointerEvents: 'none'});
        formTL.to('.form-row', .5, {opacity: 0, stagger: -0.12});
        formTL.to('#form-title', .4, {opacity: 0});
        formTL.fromTo('#form-success', {scale: 0.5, opacity: 0, x: '-50%', y: '50'}, {
            scale: 1,
            opacity: 1,
            x: '0%',
            y: '0%'
        }, '-=0.4');
        formTL.fromTo('#form-success-text', 0.8, {opacity: 0, y: '50%'}, {
            opacity: 1,
            y: '0%'
        }, '-=1.2');
        formTL.to('#form-success', 0.8, {x: '65%', y: '-50', opacity: 0, scale: 0.5,delay:0.6});
        formTL.to('#form-success-text', 0.8, {y: '60%', opacity: 0}, '-=0.6');

        formTL.to('#form-success', 0, {x: '0%', y: '0%', scale: 1});
        formTL.to('#form-title', .4, {opacity: 1});

        formTL.to('.form-row', 0.5, {opacity: 1, stagger: 0.12});

        formTL.to('#form-submit-btn', 0, {pointerEvents: 'all'});

        $.ajax({
            method: 'POST',
            url: 'https://formsubmit.co/ajax/test@weareherelabs.com',
            dataType: 'json',
            accepts: 'application/json',
            data: {
                name: name.value,
                email: email.value,
                subject: subject.value,
                message: message.value,
            },
            success: (data) => {
                name.value = '';
                message.value = '';
                email.value = '';
                subject.value = '';
                console.log(data);
            },
            error: (err) => {
                // gsap.to('#form-submit-btn', 0, {opacity: 1, y: '0%'});
                // gsap.to('.form-row', 0, {y: '0%', opacity: 0});
                // gsap.to('#form-submit-btn', 0, {pointerEvents: 'all'});
            }
        });
    }

}