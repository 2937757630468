import Splide from "@splidejs/splide";
import $ from 'jquery';
import jQuery from 'jquery';

function startSlider() {
    new Splide('.splide', {
        type: 'loop',
        perPage: 3,
        width: '100%',
        perMove: 3,
        pagination: false,
        easing: 'cubic-bezier(0.455, 0.030, 0.515, 0.955)',
        breakpoints: {
            1024: {
                perPage: 2,
                perMove: 2,
            },
            425: {
                perPage: 1,
                perMove: 1,
            },
        }
    }).mount();

}
$(document).ready(function () {
    startSlider();
});
